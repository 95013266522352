import * as React from 'react';
import { IOrderConfirmationBlockData } from './order-confirmation-block.data';
import { IOrderConfirmationBlockProps } from './order-confirmation-block.props.autogenerated';
import { Button } from '@msdyn365-commerce-modules/utilities';
import reactLogo from '../../../public/lotties/order-success-icon.json';
import { Player } from '@lottiefiles/react-lottie-player';

export interface IOrderConfirmationBlockViewProps extends IOrderConfirmationBlockProps<IOrderConfirmationBlockData> {
    addressPurposes: string;
}

export interface IOrderConfirmationBlockState {
    orderData: any;
}

class OrderConfirmationBlock extends React.PureComponent<
    IOrderConfirmationBlockProps<IOrderConfirmationBlockData>,
    IOrderConfirmationBlockState
> {
    public constructor(props: IOrderConfirmationBlockProps<IOrderConfirmationBlockData>) {
        super(props);
        this.state = {
            orderData: { showBlock: false }
        };
    }
    public componentDidMount(): void {
        const orderDetails = localStorage.getItem('order-confirmation-data');
        if (orderDetails) {
            const item = JSON.parse(orderDetails);
            const now = new Date();

            // compare the expiry time of the item with the current time
            if (item?.expiry && now.getTime() < item.expiry && item?.id) {
                let formattedDate = '';
                const dateAttribute = item && item?.result?.AttributeValues.filter((attr: any) => attr.Name === 'DeliveryDate');
                const timeAttribute = item && item?.result?.AttributeValues.filter((attr: any) => attr.Name === 'DeliveryTimeSlot');

                if (dateAttribute && dateAttribute?.length > 0 && dateAttribute && timeAttribute && timeAttribute?.length > 0) {
                    const selectedDate = dateAttribute && dateAttribute?.length > 0 ? dateAttribute[0]['TextValue'] : '';
                    const selectedTime = timeAttribute && timeAttribute?.length > 0 ? timeAttribute[0]['TextValue'] : '';
                    formattedDate = `${selectedDate}, ${selectedTime}`;
                } else {
                    const date = new Date(item?.deliveryAt);
                    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                    const dayName = date?.getDay() && days[date?.getDay()] === days[now?.getDay()] ? 'Today' : days[date?.getDay()];
                    const hours = date.getHours();
                    let timeSlot = '5pm-8pm';
                    if (hours < 8 || hours >= 16) {
                        timeSlot = '9am-12pm';
                    } else if (hours >= 8 && hours < 12) {
                        timeSlot = '1pm-4pm';
                        // } else if (hours > 12 && hours < 16) {
                        //     timeSlot = '5pm-8pm';
                    }
                    formattedDate = `${dayName}, ${date?.getDate()} ${date?.toLocaleString('default', {
                        month: 'short'
                    })} ${timeSlot}`;
                }
                this.setState({ orderData: { ...item, showBlock: true, deliveryAt: formattedDate } });
                localStorage.removeItem('order-confirmation-data');
            } else {
                localStorage.removeItem('order-confirmation-data');
                this.setState({ orderData: { showBlock: false } });
            }
        }
    }
    public render(): JSX.Element | null {
        const details = this.state.orderData;
        const addressType =
            this.props?.data?.addressPurposes?.result &&
            this.props?.data?.addressPurposes?.result.filter(
                (addressTypeValue: { AddressType: any }) => addressTypeValue?.AddressType === details?.ShippingAddress?.AddressTypeValue
            );
        const addressTypeValue = addressType ? addressType[0]?.Description : '';

        return details.showBlock ? (
            <>
                <div className='outer-order-block'>
                    <div className='order-details-block col-md-12'>
                        <div className='order-details-title col-md-3 col-12'>
                            <div className='order-success-icon'>
                                <Player src={reactLogo} className='player' loop autoplay style={{ height: '60px', width: '60px' }} />
                            </div>
                            <div>
                                <h2 className='order-details-title-heading'>Yayy! Order placed</h2>
                                <h4 className='order-details-title-id'>Status: Processing</h4>
                            </div>
                        </div>
                        <div className='order-details-address col-md-5 col-12'>
                            <p className='address-type'>Delivering order at {addressTypeValue}</p>
                            <p className='address-details'>
                                {details?.ShippingAddress?.Street}
                                {details?.ShippingAddress?.StreetNumber && `, ${details?.ShippingAddress?.StreetNumber}`}
                                {details?.ShippingAddress?.City && `, ${details?.ShippingAddress?.City}`}
                            </p>
                        </div>
                        <div className='order-details-delivery-date col-md-3 col-12'>
                            <p className='delivery-title'>Expected delivery</p>
                            <p className='delivery-details'>{details?.deliveryAt}</p>
                        </div>
                        <div className='order-details-btn-block col-md-1 col-12'>
                            <Button className='order-details-btn' href='/orderhistory'>
                                Review order
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <></>
        );
    }
}

export default OrderConfirmationBlock;
